<template>
  <div id="page-user-list">
    <div class="vx-card p-6">
      <h1>กรุณารอสักครู่...</h1>
    </div>
  </div>
</template>

<script>
import axios from '../../axios'

export default {
  data () {
    return {
    }
  },
  async mounted () {
    this.$vs.loading()
    await this.login()
  },
  methods: {
    async login () {
      try {
        // Set the base URL for Axios
        // Make a POST request to login
        const loginResult = (await axios.post('/bo/pay2coin/merchant/login')).data

        // Get the cookie from the login result
        const cookie = loginResult.cookie

        // Redirect the user with the cookie as a parameter
        const redirectUrl = `${axios.defaults.baseURL}/proxy/pay2coin/merchant/payment/history?eiei=${encodeURIComponent(cookie)}`
        window.location.href = redirectUrl
      } catch (err) {
        console.error(err.message)
      }
    }
  }
}
</script>
